import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label } from 'reactstrap';
import styled from 'styled-components';

const CustomLabel = styled(Label)`
  cursor: pointer;
`;

// Replace non-alphanumeric characters with a dash
const sanitizeLabel = label => (label || '').replace(/[^a-zA-Z0-9]/, '-');

const CheckboxWithLabel = ({ checked, onChange, label, id, ...rest }) => {
  const htmlId = id || `checkbox-input-${sanitizeLabel(label)}`;
  return (
    <div>
      <Input id={htmlId} type="checkbox" checked={checked} onChange={onChange} {...rest} />
      <CustomLabel style={{ marginLeft: 10 }} onClick={onChange} check>
        {label}
      </CustomLabel>
    </div>
  );
};

CheckboxWithLabel.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

CheckboxWithLabel.defaultProps = {
  id: '',
  checked: false,
};

export default CheckboxWithLabel;
