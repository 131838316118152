import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { setCookie } from 'nookies';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import config from '@cardash/config';
import _ from 'lodash';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  GetDistrictsQuery,
  GetOrganizationsQuery,
  GetRegionsQuery,
  GetSFACLocationsQuery,
  GetUserViewModeQuery,
  UpdateUserViewModeMutation,
} from '../components';
import { getRouteByPath, routeBelongsToViewMode, userHasRoutePermission } from '../utils/auth';

const GetOrganizations = gql`
  ${GetOrganizationsQuery}
`;
const GetRegions = gql`
  ${GetRegionsQuery}
`;
const GetDistricts = gql`
  ${GetDistrictsQuery}
`;
const GetLocations = gql`
  ${GetSFACLocationsQuery}
`;
const GetUserViewMode = gql`
  ${GetUserViewModeQuery}
`;
const UpdateUserViewMode = gql`
  ${UpdateUserViewModeMutation}
`;

export const MainContext = createContext(undefined);

const useMainContext = () => useContext(MainContext);

const getViewMode = (selectedShop, selectedDistrict, selectedRegion, selectedOrganization) => {
  if (selectedShop) {
    return config.viewModes.Location;
  }

  if (selectedDistrict) {
    return config.viewModes.District;
  }

  if (selectedRegion) {
    return config.viewModes.Region;
  }

  if (selectedOrganization) {
    return config.viewModes.Organization;
  }

  return null;
};

const useViewMode = () => {
  const router = useRouter();
  const { data: session } = useSession();
  const userId = useMemo(() => session?.user?.id || '', [session]);

  const {
    data: viewModeQueryData,
    loading: loadingViewModeDataData,
    refetch: reFetchViewModeData,
  } = useQuery(GetUserViewMode, { variables: { userId }, skip: !userId });

  const viewModeData = useMemo(() => (viewModeQueryData && viewModeQueryData.userViewMode) || {}, [viewModeQueryData]);

  const [updateUserViewMode] = useMutation(UpdateUserViewMode, {
    onCompleted: async () => {
      await reFetchViewModeData();
    },
  });

  const navigateToHomePageIfNeeded = useCallback(
    newData => {
      const route = getRouteByPath(config.routes, router.pathname);
      const { locationId, districtId, regionId, organizationId } = newData || {};
      const newViewMode = getViewMode(locationId, districtId, regionId, organizationId);
      if (!routeBelongsToViewMode(route, newViewMode)) {
        return router.push(config.routes.home.path);
      }
      if (!locationId) {
        return router.push(window.location.pathname);
      }
    },
    [router]
  );

  const handleUserViewModeUpdate = _.debounce(async newData => {
    const { locationId, districtId, regionId, organizationId } = newData || {};
    const newViewMode = getViewMode(locationId, districtId, regionId, organizationId);
    setCookie(
      null,
      'viewModeData',
      JSON.stringify({
        locationId,
        districtId,
        regionId,
        organizationId,
        viewMode: newViewMode,
      })
    );
    return updateUserViewMode({ variables: { ...(newData || {}) } });
  }, 1000);

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [currentViewMode, setCurrentViewMode] = useState();

  const { data: organizationsData, loading: loadingOrganizationsData } = useQuery(GetOrganizations, {
    fetchPolicy: 'network-only',
    skip: !session?.user,
  });

  const { data: regionsData, loading: loadingRegionsData } = useQuery(GetRegions, {
    variables: { id: selectedOrganization && selectedOrganization.id },
    skip: !selectedOrganization?.id,
    fetchPolicy: 'network-only',
  });

  const { data: districtsData, loading: loadingDistrictsData } = useQuery(GetDistricts, {
    variables: { id: selectedRegion && selectedRegion.id },
    skip: !selectedRegion?.id,
    fetchPolicy: 'network-only',
  });

  const {
    data: locationsData,
    loading: loadingLocationsData,
    refetch: reFetchLocationsData,
  } = useQuery(GetLocations, {
    variables: {
      showInactive: true,
      districtId: selectedDistrict && selectedDistrict.id,
    },
    skip: !selectedDistrict?.id,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (viewModeData.location) {
      setSelectedShop(viewModeData.location);
    }
    if (viewModeData.district) {
      setSelectedDistrict(viewModeData.district);
    }
    if (viewModeData.region) {
      setSelectedRegion(viewModeData.region);
    }
    if (viewModeData.organization) {
      setSelectedOrganization(viewModeData.organization);
    }
    if (!viewModeData.organization) {
      const organizationData =
        organizationsData?.organizations?.length === 1 ? organizationsData.organizations[0] : null;
      setSelectedOrganization(organizationData);
    }
  }, [organizationsData?.organizations, viewModeData]);

  const organizations = useMemo(
    () => (organizationsData && organizationsData.organizations) || [],
    [organizationsData]
  );

  const regions = useMemo(
    () => (selectedOrganization && regionsData && regionsData.regions) || [],
    [selectedOrganization, regionsData]
  );

  const districts = useMemo(
    () => (selectedRegion && districtsData && districtsData.districts) || [],
    [selectedRegion, districtsData]
  );

  const locations = useMemo(
    () => (selectedRegion && locationsData && locationsData.getSFACLocations) || [],
    [selectedRegion, locationsData]
  );

  const loading = useMemo(
    () =>
      (selectedDistrict && loadingLocationsData) ||
      (selectedRegion && loadingDistrictsData) ||
      (selectedOrganization && loadingRegionsData) ||
      loadingOrganizationsData ||
      loadingViewModeDataData,
    [
      selectedDistrict,
      selectedRegion,
      selectedOrganization,
      loadingLocationsData,
      loadingDistrictsData,
      loadingRegionsData,
      loadingOrganizationsData,
      loadingViewModeDataData,
    ]
  );

  const updateSelectedShop = useCallback(
    async (shopId, isNewLocation) => {
      let locationsList = locations;
      if (isNewLocation) {
        const updatedLocations = await reFetchLocationsData();
        locationsList = updatedLocations?.data?.getSFACLocations || [];
      }
      let locationId = shopId;
      let districtId = (selectedDistrict && selectedDistrict.id) || null;
      let regionId = (selectedRegion && selectedRegion.id) || null;
      let organizationId = (selectedOrganization && selectedOrganization.id) || null;
      if (shopId) {
        const shop = locationsList.find(location => location.id === shopId);

        if (!shop) {
          return;
        }
        if (!isNewLocation) {
          await navigateToHomePageIfNeeded({
            ...viewModeData,
            locationId,
            districtId,
            regionId,
            organizationId,
          });
        }

        setSelectedShop(shop);
        if (!selectedDistrict || selectedDistrict.id !== shop.district.id) {
          districtId = shop.district.id;
          setSelectedDistrict(shop.district);
        }
        if (!selectedRegion || selectedRegion.id !== shop.region.id) {
          regionId = shop.region.id;
          setSelectedRegion(shop.region);
        }
        if (!selectedOrganization || selectedOrganization.id !== shop.region.organization.id) {
          organizationId = shop.region.organization.id;
          setSelectedOrganization(shop.region.organization);
        }
      } else {
        await navigateToHomePageIfNeeded({
          ...viewModeData,
          locationId: null,
          districtId,
          regionId,
          organizationId,
        });

        locationId = null;
        setSelectedShop(null);
      }
      await handleUserViewModeUpdate({
        ...viewModeData,
        locationId,
        districtId,
        regionId,
        organizationId,
      });
    },
    [
      selectedDistrict,
      selectedRegion,
      selectedOrganization,
      handleUserViewModeUpdate,
      viewModeData,
      reFetchLocationsData,
      locations,
      navigateToHomePageIfNeeded,
    ]
  );

  const updateSelectedDistrict = useCallback(
    async (districtId, shop = null) => {
      let locationId = (shop && shop.id) || null;
      let regionId = (selectedRegion && selectedRegion.id) || null;
      const organizationId = (selectedOrganization && selectedOrganization.id) || null;
      let newDistrictId = districtId;

      if (districtId) {
        const district = districts.find(districtData => districtData.id === districtId);

        if (!district) {
          return;
        }

        await navigateToHomePageIfNeeded({
          ...viewModeData,
          locationId,
          districtId,
          regionId,
          organizationId,
        });

        setSelectedDistrict(district);
        setSelectedShop(shop);
      } else {
        await navigateToHomePageIfNeeded({
          ...viewModeData,
          locationId: null,
          districtId: null,
          regionId: null,
          organizationId,
        });
        newDistrictId = null;
        locationId = null;
        setSelectedDistrict(null);
        setSelectedShop(null);
      }

      await handleUserViewModeUpdate({
        ...viewModeData,
        locationId,
        districtId: newDistrictId,
        regionId,
        organizationId,
      });
    },
    [
      handleUserViewModeUpdate,
      viewModeData,
      selectedOrganization,
      selectedRegion,
      navigateToHomePageIfNeeded,
      districts,
    ]
  );

  const updateSelectedRegion = useCallback(
    async (regionId, district = null, shop = null) => {
      let locationId = (shop && shop.id) || null;
      let districtId = district || null;
      let newRegionId = regionId;
      if (regionId) {
        const region = regions.find(regionData => regionData.id === regionId);

        if (!region) {
          return;
        }
        await navigateToHomePageIfNeeded({
          ...viewModeData,
          locationId,
          districtId,
          regionId,
          organizationId: (selectedOrganization && selectedOrganization.id) || null,
        });

        setSelectedRegion(region);
        setSelectedDistrict(district);
        setSelectedShop(shop);
      } else {
        await navigateToHomePageIfNeeded({
          ...viewModeData,
          locationId: null,
          districtId: null,
          regionId: null,
          organizationId: (selectedOrganization && selectedOrganization.id) || null,
        });
        newRegionId = null;
        locationId = null;
        districtId = null;
        setSelectedRegion(null);
        setSelectedDistrict(null);
        setSelectedShop(null);
      }
      await handleUserViewModeUpdate({
        ...viewModeData,
        locationId,
        districtId,
        regionId: newRegionId,
        organizationId: (selectedOrganization && selectedOrganization.id) || null,
      });
    },
    [handleUserViewModeUpdate, viewModeData, selectedOrganization, navigateToHomePageIfNeeded, regions]
  );

  const updateSelectedOrganization = useCallback(
    async organizationId => {
      const locationId = null;
      let districtId = (selectedDistrict && selectedDistrict.id) || null;
      let regionId = (selectedRegion && selectedRegion.id) || null;
      let newOrganizationId = organizationId;
      if (organizationId) {
        const organization = organizations.find(organizationData => organizationData.id === organizationId);
        if (!organization) {
          return;
        }
        await navigateToHomePageIfNeeded({
          ...viewModeData,
          locationId: null,
          districtId: null,
          regionId: null,
          organizationId,
        });
        districtId = null;
        regionId = null;
        setSelectedOrganization(organization);
        setSelectedDistrict(null);
        setSelectedRegion(null);
        setSelectedShop(null);
      } else {
        await navigateToHomePageIfNeeded({
          ...viewModeData,
          locationId: null,
          districtId: null,
          regionId: null,
          organizationId: null,
        });
        districtId = null;
        regionId = null;
        newOrganizationId = null;
        setSelectedOrganization(null);
        setSelectedRegion(null);
        setSelectedDistrict(null);
        setSelectedShop(null);
      }
      await handleUserViewModeUpdate({
        ...viewModeData,
        locationId,
        districtId,
        regionId,
        organizationId: newOrganizationId,
      });
    },
    [
      selectedDistrict,
      selectedRegion,
      handleUserViewModeUpdate,
      viewModeData,
      navigateToHomePageIfNeeded,
      organizations,
    ]
  );
  const viewMode = useMemo(() => {
    if (loading) {
      return currentViewMode;
    }
    return getViewMode(selectedShop, selectedDistrict, selectedRegion, selectedOrganization);
  }, [selectedShop, selectedDistrict, selectedRegion, selectedOrganization, loading, currentViewMode]);
  useEffect(() => {
    if (viewMode !== currentViewMode) {
      setCurrentViewMode(viewMode);
    }

    // Case when the view mode has been calculated first time
    if (viewMode !== undefined && currentViewMode === undefined) {
      setCookie(null, 'viewModeData', {
        locationId: selectedShop?.id || null,
        districtId: selectedDistrict?.id || null,
        regionId: selectedRegion?.id || null,
        organizationId: selectedOrganization?.id || null,
        viewMode,
      });
    }
  }, [viewMode, currentViewMode, selectedShop?.id, selectedDistrict?.id, selectedRegion?.id, selectedOrganization?.id]);

  return {
    selectedOrganization,
    selectedRegion,
    selectedDistrict,
    selectedShop,
    updateSelectedShop,
    updateSelectedRegion,
    updateSelectedDistrict,
    updateSelectedOrganization,
    organizations,
    regions,
    districts,
    locations,
    viewMode,
    loading,
  };
};

export const MainContextProvider = ({ children }) => {
  const {
    selectedOrganization,
    selectedRegion,
    selectedDistrict,
    selectedShop,
    updateSelectedShop,
    updateSelectedRegion,
    updateSelectedDistrict,
    updateSelectedOrganization,
    organizations,
    regions,
    districts,
    locations,
    viewMode,
    loading: viewModeDataLoading,
  } = useViewMode();

  return (
    <MainContext.Provider
      value={{
        viewMode,
        organizations,
        regions,
        districts,
        locations,
        selectedDistrict,
        selectedRegion,
        selectedOrganization,
        selectedShop,
        updateSelectedShop,
        updateSelectedDistrict,
        updateSelectedRegion,
        updateSelectedOrganization,
        viewModeDataLoading,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

MainContextProvider.propTypes = {
  children: PropTypes.node,
};

MainContextProvider.defaultProps = {
  children: () => null,
};

export default useMainContext;
