import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarText,
  Spinner,
  UncontrolledDropdown,
} from 'reactstrap';
import gql from 'graphql-tag';
import { Image } from '../index';
import { GetUserQuery } from '../graphql';
import Error from '../Error';
import { signOut, useSession } from 'next-auth/react';
import useMainContext from '../../hooks/main-provider';
import Organizations from '../../components/Buttons/MenuSidebar/Organizations';
import Region from '../../components/Buttons/MenuSidebar/Region';
import ShopsSelectField from '../../components/Buttons/MenuSidebar/ShopsSelectField';
import Districts from '../../components/Buttons/MenuSidebar/Districts';

const StyledNavbar = styled(Navbar)`
  width: 100%;
  height: 55px;
  background-color: #412473 !important;
  padding: 0 !important;
`;
const StyledCollapse = styled(Collapse)`
  background-color: #412473 !important;
  position: absolute;
  right: 0;
`;

const StyledOrganizationContainer = styled(Container)`
   {
    background-color: #412473;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 55px;
    align-items: center;
    margin: 0;
    img {
      height: 40px;
      margin-left: 30px;
      margin-right: 40px;
    }
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)`
  max-width: 250px;
  right: 0;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  display: flex;
  align-items: center;
  color: white !important;

  img {
    width: auto;
    height: 39px;
    margin-right: 5px;
  }
`;

const RolesDropdownItem = styled(DropdownItem)`
  white-space: break-spaces;
  color: black !important;
`;

const SignOutDropdownItem = styled(DropdownItem)`
  &:active {
    background-color: #696666;
  }
`;

const GetUser = gql`
  ${GetUserQuery}
`;

const UserSettings = () => {
  const { selectedOrganization } = useMainContext();
  const { data: session } = useSession();

  const user = session?.user;

  const { data, loading, error } = useQuery(GetUser, {
    variables: { id: user.id || null },
    skip: !user?.id,
  });

  const userData = useMemo(() => (data && data.user) || null, [data]);

  const userRoles = useMemo(
    () => (userData ? userData.accessLevels.map(accessLevel => accessLevel.displayName).join(', ') : ''),
    [userData]
  );

  if (loading) return <Spinner />;
  if (error) return <Error error={error} />;

  if (!userData) return null;
  return (
    <StyledNavbar color="light" expand="md" fixed="top" light>
      <StyledOrganizationContainer>
        <Image src="https://sfac-static-assets.s3.us-west-1.amazonaws.com/sfac_logo white.png" alt="sfac-logo" />
        <Organizations />
        {selectedOrganization && <Region />}
        {selectedOrganization && <Districts />}
        {selectedOrganization && <ShopsSelectField />}
      </StyledOrganizationContainer>
      <StyledCollapse navbar>
        <Nav className="me-auto" navbar />
        <NavbarText>
          <UncontrolledDropdown inNavbar nav>
            <StyledDropdownToggle caret nav>
              <Image
                src="https://sfac-static-assets.s3.us-west-1.amazonaws.com/user-default-avatar-white.png"
                alt="user-avatar"
              />
              <p className="m-0">
                {userData.firstName} {userData.lastName}
              </p>
            </StyledDropdownToggle>
            <StyledDropdownMenu>
              <DropdownItem disabled>{userData.email}</DropdownItem>
              <RolesDropdownItem disabled>Your role(s): {userRoles}</RolesDropdownItem>
              <DropdownItem divider />
              <SignOutDropdownItem onClick={() => signOut()}>Sign Out</SignOutDropdownItem>
            </StyledDropdownMenu>
          </UncontrolledDropdown>
        </NavbarText>
      </StyledCollapse>
    </StyledNavbar>
  );
};

export default UserSettings;
